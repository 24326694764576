<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.faultSetting')"
    id="faultSetting"
  >
    <div id="outer-title">{{ $t("i18n.faultSetting") }}</div>
    <div>
      <p>{{ title }}</p>
      <el-checkbox-group v-model="checkboxGroup" class="m-t">
        <div class="col-item" v-for="(item, index) in faultCode" :key="index">
          <el-checkbox :label="item.failureCodeName" border :checked="item.pushable" >
            {{ item.failureCodeName }}
          </el-checkbox>
        </div>
      </el-checkbox-group>
      <div class="text-center">
        <el-button type="add" @click="submitFault">
          {{ $t("i18n.confirmsubmission") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "SetFaultPage",
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const state = reactive({
      title: "",
      checkboxGroup: [],
      faultCode: [], // 所有故障代码
      editList: [], // 编辑克隆数据
    });

    const init = async () => {
      state.title = route.query.title;
      let { data } = await proxy.$api.rescue.getFaultByType(encodeURIComponent(encodeURIComponent(state.title)));
      state.faultCode = data;
      data.map((item) => {
        if (item.pushable) {
          state.checkboxGroup.push(item.failureCodeName);
          state.editList.push(item.failureCodeName);
        }
      });
    };

    const submitFault = () => {
      // 提交控制器故障代码
      var faultCodeList = [],
        diffList = [];
      state.editList.map((item) => {
        if (state.checkboxGroup.indexOf(item) == -1) {
          diffList.push(item);
        }
      });
      state.checkboxGroup.map((item) => {
        // 选中的故障
        let obj = {
          faultCode: item,
          pushable: true,
        };
        faultCodeList.push(obj);
      });
      diffList.map((item) => {
        // 取消选中故障
        let obj = {
          faultCode: item,
          pushable: false,
        };
        faultCodeList.push(obj);
      });
      var faultCodeListCtrlTypePushDto = {
        controllerType: state.title,
        faultCodeList: faultCodeList,
      };
      proxy.$api.rescue
        .setMulFaultCode(faultCodeListCtrlTypePushDto)
        .then((res) => {
          proxy.$defined.tip(res.description, "success");
          init();
        });
    };

    init();

    return {
      ...toRefs(state),
      submitFault,
    };
  },
};
</script>

<style lang="scss">
#faultSetting {
  padding-bottom: 70px;
  p {
    font-size: 16px;
  }
  .col-item {
    width: 100px;
    display: inline-block;
    margin-bottom: 20px;
  }
}
</style>
